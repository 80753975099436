import React, { Component } from "react";
import _data from "../../data";

class Testimonial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      testimonial: {}
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
    this.setState({
      testimonial: _data.testimonial
    });
  }

  render() {
    return (
      <div>
        <section id="testimonials" className={"testimonial ptb-100 " + (this.props.bgColor && this.props.bgColor === 'gray' ? 'gray-light-bg' : '')}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12">
                <div className="section-heading text-center mb-5">
                  <p className="lead">NCC MEDIA s.r.o. is an ambitious innovative company that is attempting to turn the marketing business on its head. Our primary focus is to work closely with clients and help them succeed insetting and meeting their marketing goals. We offer total support and a commitment to communicate their ideas in a strategic, creative, and cost-effective manner.</p>
                  <p className="lead">Now we are looking for <span className="bold">Content Editor</span>. You will work with a marketing team to develop and curate compelling and engaging content for our audience.</p>
                </div>
              </div>
            </div>
            <hr></hr>
            <div className="row">
              <div className="col-md-12">
                <p className="bold">Role: Content Editor</p>
                <p className="bold">NCC Media s.r.o.</p>
                <p className="bold">Location: Prague, Czech Republic</p>
                <br></br>
                <p className="bold">Responsibilities will include:</p>
                <br></br>
                <ul className="feature-list">
                  <li>Write and edit the content of other writers as well as your own work creating and managing content plan for blogs;</li>
                  <li>Prepare publications of the different formats: white papers, interviews, thought leadership articles with stakeholders and other type of content;</li>
                  <li>Upload work through content management systems;</li>
                  <li>Work with design team to create a proper visual communication to the articles for blog, internal communications and external communications needs;</li>
                  <li>Manage the team of the translators and proofreaders;</li>
                  <li>Coordinate the work of the authors, contributors, freelancers, and guest writers;</li>
                  <li>Ensure that content meets the company’s needs, and follows our in-house style guide;</li>
                  <li>Work closely with SEO specialists;</li>
                  <li>Verify content and information;</li>
                  <li>Be proficient in desktop publishing software;</li>
                  <li>Craft and send our monthly newsletter;</li>
                  <li>Interview internal stakeholders and industry professionals and use online sources to research topics;</li>
                  <li>Manage the editorial calendar and ensure timely publication;</li>
                  <li>Generate new ideas around industry-related topics.</li>
                </ul>
                <br></br>
                <p className="bold">Required skill set:</p>
                <br></br>
                <ul className="feature-list">
                  <li>Must: fluent English and Czech languages;</li>
                  <li>Excellent verbal, writing and editing skills and the ability to write compelling stories;</li>
                  <li>Able to act on own initiative and meet deadlines;</li>
                  <li>Portfolio of published work;</li>
                  <li>Highly organized and motivated with strong leadership skills;</li>
                  <li>Ability to prioritize tasks and have excellent time management skills;</li>
                  <li>Knowledge of basic Digital PR and SEO metrics;</li>
                  <li>Journalism background would be a plus;</li>
                  <li>Openness to the professional development in the field of brand journalism;</li>
                  <li>Team spirit.</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Testimonial;
