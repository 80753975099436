import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// importing all the themes
import Main from "../themes/main";
import Theme1 from "../themes/about-us";

export default class Routes extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        <Router>
          <Switch>
            <Route exact path="/" component={Main} />
            <Route exact path="/career" component={Theme1} />
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}
