import React, {Component} from 'react'

class Footer extends Component {
    render() {
        return (
            <div>
                <footer className="footer-setion">
                    <div className="footer-top pt-5 pb-5">
                        <div className="container">
                            <div className="row justify-content-between">
                                <div className="col-lg-4 mb-4 mb-lg-0">
                                    <div className="footer-nav-wrap">
                                        <img id="logo_footer" src="img/logo_ncc_media.svg" alt="footer logo" width="180"
                                             className="img-fluid mb-3"/>
                                        {/*<p>Holisticly empower premium architectures without value-added ideas.*/}
                                        {/*    Seamlessly evolve*/}
                                        {/*    cross-platform experiences and open-source meta-services.</p>*/}

                                        {/*<ul className="list-inline">*/}
                                        {/*    <li className="list-inline-item"><span*/}
                                        {/*        className="ti-arrow-circle-right mr-2"></span> <a href="/#">Privacy*/}
                                        {/*        policy</a></li>*/}
                                        {/*    <li className="list-inline-item"><span*/}
                                        {/*        className="ti-arrow-circle-right mr-2"></span> <a href="/#">Terms*/}
                                        {/*        and Conditions</a></li>*/}
                                        {/*</ul>*/}
                                    </div>
                                </div>
                                <div className="col-lg-4 ml-auto mb-4 mb-lg-0">
                                    <div className="footer-nav-wrap">
                                        <h5 className="mb-3">Our location</h5>
                                        <ul className="list-unstyled">
                                            {/*<li className="mb-2"><span*/}
                                            {/*    className="ti-arrow-circle-right mr-2"></span><strong>Address:</strong>*/}
                                            {/*    121 King Melbourne*/}
                                            {/*    <br/>Australia 3000*/}
                                            {/*</li>*/}
                                            <li className="mb-2"><span
                                                className="ti-arrow-circle-right mr-2"></span><strong>Phone:</strong> <a
                                                href="tel:+420 731 390 042"> +420 731 390 042</a></li>
                                            <li className="mb-2"><span
                                                className="ti-arrow-circle-right mr-2"></span><strong>Email:</strong><a
                                                href="mailto:support@ncc.media"> support@ncc.media</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-bottom gray-light-bg pt-4 pb-4">
                        <div className="container">
                            <div className="row text-center text-md-left align-items-center">
                                <div className="col-md-6 col-lg-5">
                                    <p
                                        className="text-md-left copyright-text pb-0 mb-0">Copyrights © 2021. All
                                        rights reserved by
                                        <a href="/#"> NCC Media</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        )
    }
}

export default Footer;