import React from "react";

class Video extends React.Component {
    render() {
        return (
            <div>
                {/* start header section */}
                <section className="call-to-action ptb-100 background-img"
                         style={{
                             background: "url('img/img1.png')",
                             backgroundRepeat: "no-repeat",
                             backgroundPosition: "center",
                             backgroundSize: "center / cover",
                             backgroundAttachment: "fixed"
                         }}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-11">
                                <div className="call-to-action-content text-center text-white">
                                    <h2 className="text-white">Feel the power of the best customer experience with NCC
                                        Media.</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* end header section */}
            </div>
        );
    }
}

export default Video;
