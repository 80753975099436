import React, { Component } from "react";
import _data from "../../data";

class Testimonial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      testimonial: {}
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
    this.setState({
      testimonial: _data.testimonial
    });
  }

  render() {
    return (
      <div>
        <section id="career" className={"testimonial ptb-100 " + (this.props.bgColor && this.props.bgColor === 'gray' ? 'gray-light-bg' : '')}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-10">
                <div className="section-heading text-center mb-5">
                  <h2>Career</h2>
                  <p className="lead">NCC MEDIA s.r.o. is an ambitious innovative company that is attempting to turn the marketing business on its head. Our primary focus is to work closely with clients and help them succeed insetting and meeting their marketing goals. We offer total support and a commitment to communicate their ideas in a strategic, creative, and cost-effective manner.</p>
                  <p className="lead">Now we are looking for <b>Content Editor</b>. You will work with a marketing team to develop and curate compelling and engaging content for our audience.</p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <a href="/career" className="btn solid-btn ">
                Read More
              </a>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Testimonial;
