module.exports = {
  promo: {
    title: "Why does your business needs NCC Media?",
    description:
      "Here’s why you should add NCC Media to your lead pages and the list of potential partners",
    items: [
      {
        title: "Convert traffic into sales",
        description:
          "We help you attract the right traffic by building a simple and convenient website. Make your business visible on the market.",
        image: "img/service6.jpg"
      },
      {
        title: "Increase Business Trust",
        description:
          "Learn how to build trustworthiness with serious reviews. We help to develop a useful strategy for displaying testimonials.",
        image: "img/service2.jpg"
      },
      {
        title: "Predict Growth",
        description:
          "Realize the significance of the customer journey decision. We help businesses notice their organic growth and direct it in the right way.",
        image: "img/service7.jpg"
      }
    ]
  },
  promo2: {
    items: [
      {
        title: "Convert traffic into sales",
        description:
          "Increase sales by showing true dynamics of your website. Build your online store’s the trust using Social Proof & Urgency for your business.",
        image: "img/promo-1.png",
        image8: "img/service6.jpg"
      },
      {
        title: "Increase store trust",
        description:
          "Build your online store’s trust using the Social Proof, Security & Urgency. Realize the importance of social proof in customer’s purchase decision.",
        image: "img/promo-2.png",
        image8: "img/service2.jpg"
      },
      {
        title: "Growth guaranteed",
        description:
          "Realize importance of social proof in customer’s purchase decision. Increase sales by showing true dynamics, talent of your website.",
        image: "img/promo-3.png",
        image8: "img/service7.jpg"
      }
    ]
  },
  promo3: {
    items: [
      {
        title: "Convert traffic into sales",
        description:
          "Increase sales by showing true dynamics of your website. Build your online store’s the trust using Social Proof & Urgency for your business.",
        icon: "ti-bar-chart icon-color-1 icon rounded"
      },
      {
        title: "Increase store trust",
        description:
          "Build your online store’s trust using the Social Proof, Security & Urgency. Realize the importance of social proof in customer’s purchase decision.",
        icon: "ti-shield icon-color-2 icon rounded"
      },
      {
        title: "Growth guaranteed",
        description:
          "Realize importance of social proof in customer’s purchase decision. Increase sales by showing true dynamics, talent of your website.",
        icon: "ti-cup icon-color-3 icon rounded"
      }
    ]
  },
  promo6: {
    items: [
      {
        title: "Convert traffic into sales",
        description:
          "Proactively provide access to distinctive quality vectors for adaptive solutions.",
        image: "ti-bar-chart icon-md icon color-primary"
      },
      {
        title: "Increase store trust",
        description:
          "Interactively reintermediate reliable platforms rather than synergistic e-commerce.",
        image: "ti-headphone-alt icon-md icon color-primary"
      },
      {
        title: "Growth guaranteed",
        description:
          "Professionally pursue standards compliant networks whereas impactful experiences.",
        image: "ti-vector icon-md icon color-primary"
      }
    ]
  },
  promo7: {
    items: [
      {
        title: "Keyword Rankings",
        description: "Increase sales by showing true dynamics of your website.",
        image: "ti-bar-chart text-white"
      },
      {
        title: "Backlink Analytics",
        description:
          "Build your online store’s trust using Social Proof & Urgency.",
        image: "ti-vector text-white"
      },
      {
        title: "Competitor Analysis",
        description:
          "Realize importance of social proof in customer’s purchase decision.",
        image: "ti-stats-up text-white"
      }
    ]
  },
  aboutUs: {
    title: "Who we are",
    description:
      "Proactively syndicate open-source e-markets after low-risk high-yield synergy. Professionally simplify visionary technology before team driven sources.",
    items: [
      {
        title: "Increase conversion",
        description: "Display recent conversions, build credibility and trust.",
        icon: "ti-bar-chart rounded mr-3 icon icon-color-2"
      },
      {
        title: "Product analytics",
        description: "A top promo bar that counts down until a few discounts.",
        icon: "ti-stats-up rounded mr-3 icon icon-color-3"
      }
    ],
    counts: [
      {
        title: "Active users",
        count: 1600,
        icon: "ti-medall-alt color-primary",
        boxClass: "single-card box-animation-1 secondary-bg text-white",
        boxClass5:
          "single-card box-animation-1 counter-card secondary-bg text-white"
      },
      {
        title: "Global client",
        count: 2500,
        icon: "ti-headphone-alt",
        boxClass: "single-card box-animation-2 icon-color-1",
        boxClass5: "single-card box-animation-2 counter-card icon-color-1"
      },
      {
        title: "Win award",
        count: 2150,
        icon: "ti-cup",
        boxClass: "single-card box-animation-3 icon-color-2",
        boxClass5: "single-card box-animation-3 counter-card icon-color-2"
      },
      {
        title: "Clients satisfaction",
        count: 2150,
        icon: "ti-user color-secondary",
        boxClass: "single-card box-animation-4 primary-bg text-white",
        boxClass5:
          "single-card box-animation-4 counter-card primary-bg text-white"
      }
    ]
  },
  aboutUs2: {
    title: "We help to drive your business forward",
    description:
      "Focusing on a quality result as our top priority, our team of professionals strives to provide an excellent experience for clients. ",
    image: "img/hero-bg-5.jpg",
    items: [
      {
        title: "Increase Conversion",
        description: "Use real-time analytics, target only your dedicated audience.",
        longDescription:
          "",
        icon: "ti-bar-chart rounded mr-3 icon icon-color-2"
      },
      {
        title: "Product Analytics",
        description: "Receive valuable data and actionable user insights.",
        longDescription:
          "",
        icon: "ti-stats-up rounded mr-3 icon icon-color-3"
      }
    ]
  },
  aboutUs6: {
    aboutImage: "img/business.png",
    items: [
      {
        title: "Discussion for Idea",
        description:
          "Enthusiastically coordinate competitive users vis-a-vis results.",
        longDescription: "",
        icon: "ti-light-bulb rounded mr-3 icon icon-color-3"
      },
      {
        title: "Perfect testing",
        description:
          "Credibly evisculate covalent quality vectors and maintainable.",
        longDescription: "",
        icon: "ti-paint-bucket rounded mr-3 icon icon-color-4"
      }
    ]
  },
  service: {
    title: "Happy to Assist You",
    description:
      "Professionally simplify visionary technology before team driven sources.",
    items: [
      {
        title: "Search engine optimization",
        description:
          "Synergistically deliver next-generation relationships whereas bleeding-edge resources.",
        icon: "ti-bar-chart rounded mr-3 icon icon-color-1"
      },
      {
        title: "Social media marketing",
        description:
          "Phosfluorescently empower compelling intellectual capital and revolutionary web services. Compellingly develop cross-media.",
        icon: "ti-layout-media-left-alt rounded mr-3 icon icon-color-2"
      }
    ]
  },
  service2: {
    title: "We are happy to assist you with different requests in the variety of services",
    description:
      "Not only the highly professional expertise is there for our customers to meet during our cooperation. The unique and remarkable experience for each client is guaranteed.",
    items: [
      {
        title: "Search Engine Optimization",
        description:
          "Your websites are in the top list of searches and highly recognized with our modern methods.",
        icon: "ti-bar-chart icon-color-1 icon rounded",
        icon6: "ti-bar-chart icon-md color-secondary",
        image: "img/service1.jpg"
      },
      {
        title: "Social Media Marketing",
        description:
          "Customers notice you on social media as we offer the most beneficial brand strategies for your business.",
        icon: "ti-layout-media-left-alt icon-color-2 icon rounded",
        icon6: "ti-layout-media-left-alt icon-md color-secondary",
        image: "img/service3.jpg"
      },
      {
        title: "Email Marketing",
        description:
          "Use the powerful tool of email marketing to increase the engagement of your target audience.",
        icon: "ti-email icon-color-3 icon rounded",
        icon6: "ti-email icon-md color-secondary",
        image: "img/service2.jpg"
      },
      {
        title: "Affiliate Marketing",
        description:
          "Drive word of mouth and boost sales with our affiliate marketing tools.",
        icon: "ti-vector icon-color-4 icon rounded",
        icon6: "ti-vector icon-md color-secondary",
        image: "img/service7.jpg"
      },
      {
        title: "Real-time Analytics",
        description:
          "Discover the conversion rate and audience engagement online and control it right at a time.",
        icon: "ti-mobile icon-color-5 icon rounded",
        icon6: "ti-mobile icon-md color-secondary",
        image: "img/service5.jpg"
      },
      {
        title: "Digital Marketing",
        description:
          "Boost up your rate and increase brand awareness with our latest digital tools and methods.",
        icon: "ti-announcement icon-color-6 icon rounded",
        icon6: "ti-announcement icon-md color-secondary",
        image: "img/service6.jpg"
      }
    ]
  },
  service4: {
    title: "We are the marketing agency of a full cycle.",
    description:
      "With us, customers receive the complete package of services along with professional expertise.",
    items: [
      {
        title: "Automation",
        description:
          "All our processes are thoroughly automated and adjusted to the fast pace projects. ",
        icon: "ti-bar-chart rounded mr-3 icon icon-color-2"
      },
      {
        title: "Dedicated Team",
        description:
          "A team of designers is always ready to implement inspiring ideas into your projects.",
        icon: "ti-layout-media-left-alt rounded mr-3 icon icon-color-3"
      },
      {
        title: "Constant Support",
        description:
          "A dedicated consultant answers all questions and chooses the most appropriate approach to the working process.",
        icon: "ti-bar-chart rounded mr-3 icon icon-color-2"
      },
      {
        title: "Personal Approach",
        description:
          "For each client, we provide a personal manager who assists them during our cooperation.",
        icon: "ti-layout-media-left-alt rounded mr-3 icon icon-color-3"
      }
    ]
  },
  service5: {
    title: "We Are Happy to Assist You all Time MomentInsights",
    description:
      "Energistically enhance user-centric e-markets before client-focused initiatives. Enthusiastically reconceptualize proactive intellectual capital whereas top-line imperatives.",
    items: [
      {
        title: "Search engine",
        description:
          "Where we use search engines to market your product; through paid methods (Pay per Click (PPC) Services).",
        icon: "ti-bar-chart rounded mr-3 icon icon-color-2"
      },
      {
        title: "Social media marketing",
        description:
          "While browsing Facebook or Twitter or even Linkedin, you are subjected to advertisements.",
        icon: "ti-layout-media-left-alt rounded mr-3 icon icon-color-3"
      }
    ],
    items2: [
      {
        title: "Email marketing",
        description:
          "Email marketing is one of the most popular strategies offered by full-service digital marketing agencies.",
        icon: "ti-email rounded mr-3 icon icon-color-3"
      },
      {
        title: "Web design and development",
        description:
          "Your website provides a place for interested users to learn more about your business, your products and services.",
        icon: "ti-vector rounded mr-3 icon icon-color-4"
      }
    ]
  },
  service6: {
    title: "Market your business to own your market",
    description:
      "We provide professional services for grow your business and increases sell digital product"
  },
  serviceAboutUs: {
    title: "We are Agency, We Create Solutions",
    description:
      "We provide professional services for grow your business and increases sell digital product",
    items: [
      {
        title: "Our Mission",
        description: "Where we use search engines to market your product; through paid methods (Pay per Click (PPC) Services)",
        image: "img/mission.jpg"
      },
      {
        title: "Our Vision",
        description: "While browsing Facebook or Twitter or even Linkedin, you are subjected to advertisements",
        image: "img/vision.jpg"
      },
      {
        title: "Why Us?",
        description: "Email marketing is one of the most popular strategies offered by full-service digital marketing agencies",
        image: "img/grow.jpg"
      },
    ]  
  },
  price: {
    title: "Pricing Packages",
    description:
      "Uniquely maintain efficient expertise whereas cross functional deliverables. Continually leverage other's competitive mind share whereas enabled channels. ",
    packages: [
      {
        price: "$29",
        license: "Basic License",
        isFeatured: false,
        features: [
          "Push Notifications",
          "Data Transfer",
          "SQL Database",
          "Search & SEO Analytics",
          "24/7 Phone Support",
          "2 months technical support",
          "2+ profitable keyword"
        ]
      },
      {
        price: "$50",
        license: "Popular License",
        isFeatured: true,
        features: [
          "Push Notifications",
          "Data Transfer",
          "SQL Database",
          "Search & SEO Analytics",
          "24/7 Phone Support",
          "6 months technical support",
          "30+ profitable keyword"
        ]
      },
      {
        price: "$120",
        license: "Extended License",
        isFeatured: false,
        features: [
          "Push Notifications",
          "Data Transfer",
          "SQL Database",
          "Search & SEO Analytics",
          "24/7 Phone Support",
          "1 year technical support",
          "70+ profitable keyword"
        ]
      }
    ]
  },
  testimonial: {
    title: "What customers say about us",
    description:
      'Rapidly growing company with a professional team of experts and loyal people.',
    comments: [
      {
        name: "Anita Jones",
        company: "WinCo",
        image: "img/AJ.png",
        comment:
          "The team is really nice and proactive, they offered super innovative solutions for my requests. I recommend them to everyone who wants to bring something new into their business."
      },

      {
        name: "David Berkley",
        company: "Hash Inc.",
        image: "img/DB.png",
        comment:
          "This is not only the company that implements new approaches to your business. This is the place where you can feel at home. People here are extremely friendly and positive."
      },
      {
        name: "Hanna Lee",
        company: "Lenan Post",
        image: "img/HL.png",
        comment:
          "They helped me incorporate completely new strategies into the system of my business. Owing to this company, my brand increased its popularity. Absolutely recommend their services."
      }
    ]
  },
  contact: {
    title: "Contact us",
    description:
      "You can easily reach out to us using the contact form. Just insert your data and our managers will contact you shortly.",
    office: "Head Office",
    address: "",
    phone: "",
    email: "support@ncc.media"
  },
  hero: {
    title: "NCC Media - We develop and create marketing strategies and brands.",
    description:
      "We offer solutions for life and businesses.",
    bgImage: "img/hero-bg-2.jpg",
    animations: [
      {
        className: "img-fluid d-block animation-one",
        src: "img/hero-animation-04.svg",
        width: ""
      },
      {
        className: "img-fluid d-none d-lg-block animation-two",
        src: "img/hero-animation-01.svg",
        width: "120"
      },
      {
        className: "img-fluid d-none d-lg-block animation-three",
        src: "img/hero-animation-02.svg",
        width: "120"
      },
      {
        className: "img-fluid d-none d-lg-block animation-four",
        src: "img/hero-animation-03.svg",
        width: "230"
      }
    ]
  },
  hero2: {
    title: "NCC Media",
    description:
        "We develop and create marketing strategies and brands. We offer solutions for life and businesses.",
    bgImage: "img/hero-bg-1.jpg",
    counters: [
      {
        name: "Happy Client",
        count: 2350
      },
      {
        name: "App Download",
        count: 2150
      },
      {
        name: "Total Rates",
        count: 2350
      },
      {
        name: "Awards win",
        count: 2250
      }
    ]
  },
  hero3: {
    bgImage: "img/curve-bg.jpg",
    images: {
      img1: "img/hero-animation-01.svg",
      img2: "img/hero-animation-02.svg",
      img3: "img/hero-animation-03.svg",
      img4: "img/hero-animation-04.svg"
    },
  },
  hero4: {
    title: "Build anything you want",
    description:
      "We offer Digital Marketing / SEO in Kalawad Road Rajkot, Gujarat. Find here details about our company",
    bgImage: "img/hero-bg-1.jpg"
  },
  hero5: {
    title: "Build anything you want",
    description:
      "Progressively monetize cost effective innovation vis-a-vis cross-unit infrastructures. Uniquely e-enable front-end core competencies vis-a-vis premier",
    bgImage: "img/hero-bg-4.jpg"
  },
  hero7: {
    title: "We are Digital Agency & Marketing",
    description:
      "Professionally simplify integrated partnerships via enterprise-wide leadership skills. Appropriately streamline cross functional infomediaries without resource maximizing internal",
    bgImage: "img/hero-bg-1.jpg"
  },
  heroAboutUs: {
    title: "About Us",
    bgImage: "img/hero-bg-1.jpg"
  },
  trustedCompany: [
    "img/client-1-color.png",
    "img/client-6-color.png",
    "img/client-3-color.png",
    "img/client-4-color.png",
    "img/client-5-color.png"
  ],
  trustedCompanyGray: [
    "img/client-1-gray.png",
    "img/client-2-gray.png",
    "img/client-3-gray.png",
    "img/client-4-gray.png",
    "img/client-5-gray.png"
  ],
  trustedCompanyAboutUs: {
    title: "Trusted by companies",
    description: "Rapidiously morph transparent internal or \"organic\" sources whereas resource sucking e-business. Conveniently innovate compelling internal.",
    companies: [
      "img/client-5-color.png",
      "img/client-1-color.png",
      "img/client-6-color.png",
      "img/client-3-color.png",
      "img/client-4-color.png",
      "img/client-5-color.png",
      "img/client-7-color.png",
      "img/client-2-color.png",
      "img/client-1-color.png",
      "img/client-3-color.png"
    ]
  },
  teamMember: {
    members: [
      "img/team-1.jpg",
      "img/team-2.jpg",
      "img/team-3.jpg",
    ]
  },
  video: {
    link: "https://www.youtube.com/watch?v=9No-FiEInLA"
  }
};
