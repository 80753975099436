import React, {Component} from 'react'

import HeaderSection from '../components/Header/header3';
import HeroSection from '../components/HeroSection/heroSection3';
import PromoSection from '../components/PromoSection/Promo3';
import AboutSection from '../components/AboutUs/AboutUs2';
import VideoSection from '../components/Video';
import ServiceSection from '../components/Service/Service4';
import TestimonialSection from '../components/Testimonial';
import ContactSection from '../components/Contact';
import ActionToCallSection from '../components/ActionToCall';
import FooterSection from '../components/footer/footer';
import ServiceSection2 from '../components/Service/Service2';


class Theme extends Component{
    render(){
        return(
            <div>
                <HeaderSection />
                <div id="main" className="main">
                    <HeroSection />
                    <PromoSection />
                    <AboutSection />
                    <VideoSection />
                    <ServiceSection />
                    <ServiceSection2 />
                    <ActionToCallSection />
                    <TestimonialSection />
                    <ContactSection />
                </div>
                <FooterSection />
            </div>
        )
    }
}

export default Theme;