import React, {Component} from 'react'

import Header from '../components/Header/header';
import HeroSection from '../components/HeroSection/heroSectionAboutUs';
import Career from '../components/Career';
import Footer from '../components/footer/footer';
import ContactSection from "../components/Contact";


class AboutUs extends Component{
    render(){
        return(
            <div id="car">
                <Header />
                <div className="main">
                    <HeroSection title="Career" />
                    <Career />
                    <ContactSection />
                </div>
                <Footer />
            </div>
        )
    }
}

export default AboutUs;